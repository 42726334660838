import { addLocale } from 'next/dist/shared/lib/router/utils/add-locale';
import NextLink, { LinkProps } from 'next/link';
import React, { AnchorHTMLAttributes, memo, useMemo } from 'react';
import { useMarket } from 'app-shared/lib/i18n/hooks/use-market';

const LinkComponent = (
  // Props should be exactly the same as they are for NextLink component
  props: Omit<AnchorHTMLAttributes<HTMLAnchorElement>, keyof LinkProps> &
    LinkProps & {
      children?: React.ReactNode;
    } & React.RefAttributes<HTMLAnchorElement>,
) => {
  /**
   * This is an exception from the rule to not use state-aware hooks in app shared. This is because
   * the component is heavy used and because we want it to pretend to be a Next.js Link component
   * (which obviously does not have `market` property).
   */
  const market = useMarket();
  const { as, href, ...rest } = props;
  const asModified = useMemo(
    () => (as ? addLocale(as.toString(), market?.prefix) : undefined),
    [as, market?.prefix],
  );

  const hrefModified = useMemo(
    () => addLocale(href.toString(), market?.prefix),
    [href, market?.prefix],
  );

  return <NextLink {...rest} as={asModified} href={hrefModified} />;
};

export const Link = memo(LinkComponent);
