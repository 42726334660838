import { CountryCodes } from '@bridebook/toolbox/src/gazetteer';
import type { Slug } from '@bridebook/toolbox/src/types';
import { salesAssignments } from './hubspot-sales-assignments';
import { HubspotIds } from './types';

export const getHubspotLocationLink = (id: HubspotIds) =>
  salesAssignments[id]?.url || '/cms-bridebook-call';

export const countryCodeToHubspotId: Record<string, HubspotIds> = {
  [CountryCodes.DE]: HubspotIds.AnisBerthold,
  [CountryCodes.FR]: HubspotIds.Simon,
  [CountryCodes.IE]: HubspotIds.DylanPackham,
};

export const getHubspotId = ({
  countryCode,
  county = '',
  supplierType,
}: {
  countryCode: string;
  county?: string;
  supplierType?: Slug;
}): HubspotIds => {
  if (countryCodeToHubspotId[countryCode]) {
    return countryCodeToHubspotId[countryCode];
  }

  // Non-venues
  if (supplierType !== 'venue') {
    return HubspotIds.ZaraMughal;
  }

  // Venues
  return (
    Object.values(salesAssignments).filter((item) => item.regionsAssigned.includes(county))[0]
      ?.id || HubspotIds.Sarah
  );
};
